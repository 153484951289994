// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    
    products: 'https://e72ykhmx6fdo3ch4sx2ivp44xa.appsync-api.eu-central-1.amazonaws.com/graphql'
  },
  auth: {
    userPoolId: 'eu-central-1_bqlYlCdrd',
    clientId: 'kl0u9uthn8tde69f1bfndaoud',
    appWebDomain: 'covestro-dsl-dev-vcop.auth.eu-central-1.amazoncognito.com',
    identityProvider: 'devADvcop',
    apiKey: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
